.website-footer {
  background-color: #fdfaf5;
  padding: 0 50px;
  margin-top: 200px;
  .footer-top {
    padding: 100px 0;
    margin-top: 100px;
    .footer-title {
      font-family: 'Playfair Display';
      font-size: 50px;
      text-align: right;
      line-height: 1.1;
    }
    .footer-title-line {
      border-top: solid 2px #eeeeee;
      margin: 50px 0;
    }
    .footer-subtitle {
      font-size: 16px;
      line-height: 16px;
      text-align: right;
    }
  }
  .footer-divider {
    border-top: solid 2px #eeeeee;
  }
  .footer-bottom {
    padding: 35px 0;
    .social-icon {
      width: 23px;
      height: 23px;
      margin: 0 9px;
    }
    .footer-social-icons {
      display: flex;
      justify-content: space-around;
    }
    .footer-menu {
      display: flex;
      justify-content: flex-end;
      font-weight: 600;
      color: #2b2d42;
      a {
        color: inherit;
      }
    }
  }
}

@media (max-width: 768px) {
  .website-footer {
    padding: 0 20px;
    margin-top: 200px;
    .footer-top {
      padding: 35px 0;
      .footer-title {
        font-size: 40px;
        text-align: center;
      }
      .footer-title-line {
        margin: 20px 0;
      }
      .footer-subtitle {
        text-align: center;
      }
    }
    .footer-bottom {
      .copyright {
        text-align: center;
      }
      .footer-menu {
        padding: 30px 0;
      }
      .footer-social-icons {
        margin: 50px 0;
      }
    }
  }
}

@media (max-width: 360px) {
  .website-footer {
    margin-top: 300px;
  }
}

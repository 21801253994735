.pisco-pane {
    z-index: auto;
    .slide-pane__content {
        padding: 0;
    }
}
#lightboxBackdrop{
    z-index: 99999;
}
.react-tagsinput-tag {
    position: unset;
}
#pisco-video-container {
  margin-left: -30px;
  margin-right: -30px;
  background: white;
}

#pisco-video-container hr {
  margin-bottom: 10px;
}

// Make Pisco pane 100% width only on mobile/tablet devices
@media (max-width: 768px) {
  .pisco-pane{
    >.slide-pane {
      width: 100% !important;
    }
  }
  #pisco-video-container {
    margin-left: -20px;
  }
}

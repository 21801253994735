@import url('https://use.typekit.net/ndw0utv.css') ;

.GridContainer {
    margin-top: 150px;
}

.website-header {
  #hamburger {
    display: none;
  }
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 104px;
  background-color: white;
  color: black;
  z-index: 1299;
  border-bottom: solid 1px #e1e0e1;
  .mobile-nav {
    display: none;
    .navbar-items {

    }
  }
  .website-header-inner {
    .navbar-mob-top {
      text-align: center;
      height: 100%;
      z-index: 6;
      background-color: white;
    }
    .hamburger-container {
      display: flex;
      height: 100%;
    }
    width: 750px;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    border-bottom: solid 1px #eeeeee;
    .navbar-logo {
      padding-top: 2rem;
      .logo {
        height: 40px;
      }
    }
    .signin-button-container {
      text-align: center;
      padding: 0 15px;
      .signin-button {
        cursor: pointer;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 9px;
        padding-bottom: 9px;
        margin-top: 30px;
        border: solid 0.8px #fb4d24;
        color: #fb4d24;
        background-color: transparent;
        border-radius: 4px;
        font-weight: 500;
      }
      .signin-button:hover {
        color: white;
        background-color: #fb4d24;
      }
    }
    .navbar-items-container {
      margin: auto;
      padding-left: 50px;
      .navbar-items {
        list-style-type: none;
        display: flex;
        margin: 0;
        font-family: acumin-pro, sans-serif;
        font-weight: 400;
        li {
          a {
            text-decoration: none;
            color: inherit;
            padding: 17px;
            letter-spacing: 0.69px;
            line-height: 24px;
            position: relative;
            span {
              padding: 1px 4px;
              border-radius: 2px;
              transition: all 0.3s;
            }
            span::after {
              content: '';
              position: absolute;
              width: 76%;
              transform: scaleX(0);
              height: 2px;
              bottom: 10px;
              left: 12px;
              background-color: #fb4d24;
              transform-origin: bottom center;
              transition: transform 0.25s ease-out;
            }
            span:hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
            span:hover {
              color: black;
              background-color: white;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768) {
    .website-header-inner {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .website-header-inner {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .website-header-inner {
      width: 1140px;
    }
  }

}

// mobile Header
@media (min-width: 768px) {
  .GridContainer {
    margin-top: 50px !important;
  }
}

@media (max-width: 991px) {
  .website-header {
    .navbar-logo {
      padding-top: 1.35rem !important;
      text-align: center;
      .logo {
        height: auto !important;
        width: 100%;
        max-width: 250px;
      }
    }
  }
  .GridContainer {
    width: 90vw;
    margin-top: 100%;
  }
  #hamburger {
    display: block !important;
    position: absolute;
    left: 5px;
    top: 30px;
  }
  .website-header {
    height: 72px;
    .mobile-nav {
      .hamburger-close {
        text-align: right;
        padding-right: 2rem;
      }
      width: 60%;
      display: block;
      position: fixed;
      height: 100%;
      background-color: white;
      z-index: 7;
      padding-top: 3rem;
      //transform: translateX(-100%);
      font-size: 1.25rem;
      font-weight: 700;
      transition: transform .4s;
      .navbar-items {
        list-style-type: none;
        li {
          padding: 1rem 0.5rem;
          a {
            color: #fb4d24;
          }
        }
      }
      &.hidden {
        transform: translateX(-100%);
      }
    }
    .website-header-inner {
      flex-direction: column;
      width: 100vw;
      padding: 0;
      cursor: pointer;
      .signin-button-container {
        position: absolute;
        z-index: 6;
        right: 0;
        top: 0;
        .signin-button {
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding: 7px 20px;
        }
      }
      .navbar-items-container {
        display: none;
      }
      .navbar-items {
        flex-direction: column;
        position: absolute;
        left: 0;
        width: 100vw;
        padding: 0;
        margin: 0;
        margin-top: -1px;
        background-color: black;
        max-height: 100vh;
        margin-top: 0;
        transition: margin-top 0.4s ease-in-out;
        li {
          display: table;
            a {
              display: table-cell;
              padding: 20px 10px 15px;
              letter-spacing: 1px;
              line-height: 20px;
            }
        }
        &.hidden {
          margin-top: -100%;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .GridContainer {
    margin-top: -100px !important;
  }
}
@media (min-width: 1024px) {
  .navbar-items {
    margin-left: -100px !important;
  }
}
@media (max-width: 375px) {
  .GridContainer {
    margin-top: 150% !important;
  }
}
@media (max-width: 360px) {
  .GridContainer {
    margin-top: 180% !important;
  }
}
@media (max-width: 320px) {
  .GridContainer {
    margin-top: 220% !important;
  }
}

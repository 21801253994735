
.ant-modal-mask {
  z-index: 1400;
}
.ant-modal-wrap {
  z-index: 1400;
}
.rescheduleContainer {
  .rdtPicker {
    margin-top: 30px !important;
  }
}


input[value=""]::-webkit-datetime-edit{ color: transparent; }
input:focus::-webkit-datetime-edit{ color: #000; }

.login-heading {
  font-family: 'Playfair Display';
  font-size: 50px;
  line-height: 1.1;
  margin-left: 0;
}

.text-underline {
  border-top: solid 2px #eeeeee;
  margin: 30px 0;
  width: 50px;
}

.form-name {
  font-size: 20px;
  line-height: 0.85;
}

// Fixes Material UI multi-line text field padding issue
.multilineFormFix {
  div {
    textarea {
      box-sizing: border-box;
      &::placeholder {
        padding: 0;
      }
    }
  }
}

.labelPadding {
  >div {
    >label {
      padding: 2px 0 0 10px;
    }
  }
}
div.navbar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1200;
  justify-content: space-between;
  padding-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
  border: none;
  box-sizing: border-box;
  display: none;
 }
 div.navbar-list{
  padding: 0;
  position: relative;
  text-decoration: none;
  display: flex;
  max-width: 70px;
 }
 div.navbar-list-item, a.navbar-list-item{
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: center;
 }
 div.navbar-list-item.active, a.navbar-list-item.active{
  background: transparent;
  border-radius: 0;
  border-bottom: 2px solid #000;
 }
 div.navbar-item-text{
  padding: 0;
 }
 a.submenu-navlink{
  padding-left: 0;
  margin-left: 0;
 }

@media (max-width: 768px) {
  .signup-form {
    margin-top: 50px;
  }
  div.intercom-namespace .intercom-mthgtn{
    bottom: 70px;
  }
  div.navbar-container{
    display: flex;
  }
  .sidebar-list-container{
    display: none;
  }
  .WithSubscription-AppLayout--content-22{
    min-height: calc(100vh - 193px);
    margin-bottom: 140px;
  }
}
.permission-category-container{
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 20px;
  .permission-category-heading{
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #eee;
    text-align: center;
  }
  .contolled-label{
    margin: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    display: flex;
  }
}
#liveArticleSearch{
  &::placeholder{
    font-size: 14px;
  }
}
